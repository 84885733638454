import React, { Fragment } from 'react';

const Spinner = () => (
  <Fragment>
    <div className='fa-2x'>
      <i className='fas fa-spinner fa-spin'></i>
    </div>
  </Fragment>
);

export default Spinner;
