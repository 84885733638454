import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import Playlist from './Playlist';
import Time from './Time';
import WhatsOn from './WhatsOn';
import PropTypes from 'prop-types';
import '../../Screen.css';
import logo from '../layout/logo.svg';

export class Screen extends Component {
  constructor(props) {
    super(props);

    this.playCarousel = this.playCarousel.bind(this);
    this.pauseCarousel = this.pauseCarousel.bind(this);

    this.state = {
      nodeUpdated: null,
      activeSlide: 0
    };
  }

  playCarousel() {
    this.slider.slickPlay();
  }

  pauseCarousel() {
    this.slider.slickPause();
  }

  intervalID = 0;

  componentDidMount() {
    // Get screen from UUID in url
    this.props.getScreen(this.props.match.params.id);

    // Get screen every 30.5 seconds
    this.intervalID = setInterval(() => {
      this.props.getScreen(this.props.match.params.id);

      this.setState({
        nodeUpdated: this.props.screen.data.changed
      });
    }, 31000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.nodeUpdated !== null) {
      // Reload the app if screen api has changed
      if (this.state.nodeUpdated !== prevState.nodeUpdated) {
        window.location.reload();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    screen: PropTypes.object.isRequired,
    getScreen: PropTypes.func.isRequired
  };

  /* state = {
    oldSlide: 0,
    activeSlide: 1,
    updated: null
  }; */

  render() {
    // Slick carousel settings
    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      autoplay: true,
      pauseOnHover: false,
      draggable: false,
      speed: 500,
      beforeChange: (current, next) => {
        this.setState({
          activeSlide: next
        });
      },
      afterChange: () => {
        const currentSlide = document
          .querySelector('.slick-current div div')
          .getAttribute('class');

        if (currentSlide.includes('video')) {
          this.pauseCarousel();

          const currentVideo = document.getElementById(
            `theVideo${this.state.activeSlide}`
          );

          currentVideo.play();

          document
            .getElementById(`theVideo${this.state.activeSlide}`)
            .addEventListener(
              'ended',
              function(e) {
                currentVideo.pause();
                this.slider.slickGoTo(this.state.activeSlide + 1);
                this.playCarousel();

                setTimeout(function() {
                  currentVideo.currentTime = 0;
                }, 1000);
              }.bind(this)
            );
        }
      }
    };

    // https://stackoverflow.com/questions/50417895/cant-access-nested-json-objects-in-react
    if (!this.props.screen.data) {
      return null;
    }

    const sidebar = this.props.screen.data.field_screen_layout;

    return (
      <div
        className={`advert ${
          sidebar === 'side_bottom' ? 'grid-container' : ''
        }`}
      >
        <div className='main'>
          <Slider
            ref={slider => (this.slider = slider)}
            {...settings}
            autoplaySpeed={`${this.props.screen.data.field_playlist_reference.field_slide_duration}0`}
          >
            {this.props.screen.data.field_playlist_reference.field_playlist.map(
              (include, index) => (
                <Playlist key={include.id} playlist={include} index={index} />
              )
            )}
          </Slider>
        </div>

        {sidebar === 'side_bottom' && (
          <Fragment>
            <div className='sidebar'>
              <img
                src={logo}
                alt=''
                style={{
                  height: '60px',
                  width: 'auto',
                  margin: '0 auto 20px',
                  display: 'block'
                }}
              />
              <Time />
              <WhatsOn />
            </div>
            <div className='footer'>
              {this.props.screen.data.field_screen_message}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default Screen;
