import React, { Fragment } from 'react';

const About = () => {
  return (
    <Fragment>
      <h1>About this app</h1>
      <p>App to demonstrate digital signage on TV displays</p>
      <p>Version: 0.2.0</p>
    </Fragment>
  );
};

export default About;
