import React, { Component, Fragment } from 'react';
//import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import axios from 'axios';

export class WhatsOn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      whatson: []
    };
  }

  intervalID = 0;

  getWhatsOn = async () => {
    const d = Math.floor(Date.now() / 1000);

    const res = await axios.get(
      `https://woolacombesands.inventive.host/api/node/whatson?_format=json&time=${d}`
    );
    this.setState({ whatson: res.data, loading: false });
  };

  componentDidMount() {
    // Get whats on
    this.getWhatsOn();

    // Get whats on every 30 seconds
    this.intervalID = setInterval(() => {
      this.getWhatsOn();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    } else {
      return (
        <Fragment>
          <h2>What's On Today</h2>
          {this.state.whatson.map((whatson, index) => (
            <div className='whatsOnItem' key={index}>
              <div className='whatsOnTime'>
                {whatson.start}
                {whatson.end}
              </div>
              <div className='whatsOnTitle'>{whatson.title}</div>
            </div>
          ))}
        </Fragment>
      );
    }
  }
}

/* Playlist.propTypes = {
  playlist: PropTypes.object.isRequired
}; */

export default WhatsOn;
