import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ScreenItem = ({ screen: { id, attributes } }) => {
  return (
    <Link to={`/screen/${id}`} className='card text-center'>
      <h3>{attributes.title}</h3>
    </Link>
  );
};

ScreenItem.propTypes = {
  screen: PropTypes.object.isRequired
};

export default ScreenItem;
