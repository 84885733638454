import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Playlist = ({ playlist, index }) => {
  if (playlist.type === 'media--video') {
    return (
      <div
        className={`fullscreen-bg video`}
        key={playlist.field_media_video_file.id}
      >
        <video
          id={`theVideo${index}`}
          width='320'
          height='180'
          preload='none'
          muted
          playsInline
        >
          <source
            src={`https://ds.cms.inventive.host${playlist.field_media_video_file.uri.url}`}
            type={playlist.field_media_video_file.filemime}
          />
        </video>
      </div>
    );
  } else if (playlist.type === 'media--image') {
    return (
      <div className={`fullscreen-bg`} key={playlist.field_media_image.id}>
        <img
          src={`https://ds.cms.inventive.host${playlist.field_media_image.uri.url}`}
          alt=''
        />
      </div>
    );
  } else {
    return <Fragment>Cannot read field.</Fragment>;
  }
};

Playlist.propTypes = {
  playlist: PropTypes.object.isRequired
};

export default Playlist;
