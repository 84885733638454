import React, { Fragment, Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Screens from './components/screens/Screens';
import Screen from './components/screens/Screen';
import About from './components/pages/About';
import axios from 'axios';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class App extends Component {
  state = {
    screens: [],
    screen: {},
    loading: false
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const res = await axios.get(
      `https://ds.cms.inventive.host/jsonapi/node/screen?sort=title`
    );
    this.setState({ screens: res.data.data, loading: false });
  }

  // Get single screen
  getScreen = async uuid => {
    this.setState({ loading: true });

    const res = await axios.get(
      `https://ds.cms.inventive.host/jsonapi/node/screen/${uuid}?include=field_playlist_reference.field_playlist.field_media_image,field_playlist_reference.field_playlist.field_media_video_file&jsonapi_include=1`
    );

    this.setState({
      screen: res.data,
      loading: false
    });
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path='/screen/:id'
            render={props => (
              <Screen
                {...props}
                getScreen={this.getScreen}
                screen={this.state.screen}
                loading={this.state.loading}
              />
            )}
          />
          <Fragment>
            <div className='App'>
              <Navbar />
              <div className='container'>
                <Route
                  exact
                  path='/'
                  render={props => (
                    <Fragment>
                      <Screens
                        loading={this.state.loading}
                        screens={this.state.screens}
                      />
                    </Fragment>
                  )}
                />

                <Route exact path='/about' component={About} />
              </div>
            </div>
          </Fragment>
        </Switch>
      </Router>
    );
  }
}

export default App;
