import React from 'react';
import ScreenItem from './ScreenItem';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';

const Screens = ({ screens, loading }) => {
  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div style={screenStyle}>
        {screens.map(screen => (
          <ScreenItem key={screen.id} screen={screen} />
        ))}
      </div>
    );
  }
};

Screens.propTypes = {
  screens: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

const screenStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: '1rem'
};

export default Screens;
