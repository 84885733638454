import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';

export class Time extends Component {
  render() {
    const date = new Date();

    return (
      <Fragment>
        <div className='dateTime'>
          <Moment format='HH:mm'>{date}</Moment>
        </div>
        <div className='dateDate'>
          <Moment format='dddd DD MMMM YYYY'>{date}</Moment>
        </div>
      </Fragment>
    );
  }
}

export default Time;
